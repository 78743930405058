import { useContext } from 'react';

import { ApiNumber } from 'types/models/ApiNumber';
import { ConfigContext } from '@providers/ConfigProvider';
import PartnerStar from 'assets/icons/partner-star.svg';
import { FormattedMessage } from 'utils/intl';
import { getNumber } from 'utils/getNumber';

import ShopBanner from '../ShopBanner';
import classes from './DellBanner.module.scss';
import messages from './DellBanner.messages';

type Props = {
  numbers: ApiNumber[];
};

const DellBanner = ({ numbers }: Props): JSX.Element => {
  const {
    contact: { appName },
  } = useContext(ConfigContext);
  const dellowoStartYear = getNumber(numbers, 'DELLOWO_START_YEAR');

  return (
    <ShopBanner
      classes={{
        root: classes.wrapper,
        header: classes.header,
        images: classes.imageWrapper,
        image: classes.image,
      }}
      images={['dell']}
      header={
        <>
          <PartnerStar className={classes.partnerStar} />
          <div className={classes.headerText}>
            <FormattedMessage {...messages.header} />
          </div>
        </>
      }
      description={<FormattedMessage {...messages.description} values={{ appName, dellowoStartYear }} />}
      additionalContent={<FormattedMessage {...messages.logoText} />}
    />
  );
};

export default DellBanner;
